<template>
  <div class="primeiroAcesso">
    <div class="primeiroAcesso__container">
      <LogoEmpresa />
      <h1>Bem-vindo (a)</h1>
      <div class="primeiroAcesso__formContainer">
        <label>Assistência</label>
        <multiselect
          v-model="form.cliente"
          label="assistencia"
          track-by="id"
          :options="dados"
          :multiple="false"
          :options-limit="200"
          placeholder=""
          select-label="Selecionar"
          deselect-label="Remover"
          selected-label="Selecionado"
        />

        
      </div>
      <div class="primeiroAcesso__btn">
        <button @click="formPrimeiroAcesso" class="continuar">
          CONTINUAR
        </button>
        <button @click="fecharModal" class="voltar">
          VOLTAR
        </button>
      </div>

      <div class="primeiroAcesso__descricao">
        <div class="container">
          <p class="">Dúvidas?</p>
          <p class="descricao">Ligue para 0800 780 0606 e fale com um de nossos atendentes.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
export default {
  name: "ModalPrimeiroAcesso",

  components: {
    LogoEmpresa,
    Multiselect
  },

  data() {
    return {
      dados: [],
      form: {
        cliente: null
      },

      ativo: this.$store.state.interacoes.modalPrimeiroAcesso.modalAtivado,
    }
  },
  methods: {
    ...mapActions({
      defineAssistenciaId: "assistencia/defineAssistenciaId"
    }),
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_PRIMEIRO_ACESSO", {
        ativado: false,
        mensagem: "PrimeiroAcesso",
        tipo: "",
      });
    },

    formPrimeiroAcesso() {
      if(this.form.cliente.id) {
        this.defineAssistenciaId(this.form.cliente.id)
        this.$router.push("/cadastro")
        this.$store.commit("interacoes/SET_MODAL_PRIMEIRO_ACESSO", {
          ativado: false,
          mensagem: "PrimeiroAcesso",
          tipo: "",
        })
      }
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.multiselect {
  &__tags {
    background-color: $branco !important;
  }
}

.primeiroAcesso {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  &__container {
    width: 762px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 72px 0 92px 0;
    @media(max-width: 768px) {
      width: 100%;
    }
    img {
      width: 360px;
      @media(max-width: 768px) {
        width: 302px;
      }
    }

    h1 {
      font-weight: 500;
      font-size: 32px;
      color: $grafite;
      margin: 48px 0;
    }
  }

  &__formContainer {
    width: 543px !important;
    padding: 0 !important;

    @media(max-width: 768px) {
      width: 100% !important;
      padding: 0 37px !important;
    }

    label {
      font-weight: 500;
      font-size: 14px;
      color: $neutro3;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;

    @media(max-width: 768px) {
      width: 100%;
      padding: 0 37px;
    }

    .continuar {
      border: none;
      background: $bg-btn-padrao;
      width: 543px;
      margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media(max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .voltar {
      border: none;
      background: transparent;
      width: 543px;
      margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $grafite;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      transition: hover 0.3;

      @media(max-width: 768px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__descricao {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      width: 264px;
      text-align: center;

      .duvidas {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $grafite;
      }

      .descricao {
        font-size: 14px;
        line-height: 20px;
        color: $grafite;
      }
    }
  }
}
</style>