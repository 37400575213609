import { render, staticRenderFns } from "./ModalPrimeiroAcesso.vue?vue&type=template&id=1f4d69f1&scoped=true"
import script from "./ModalPrimeiroAcesso.vue?vue&type=script&lang=js"
export * from "./ModalPrimeiroAcesso.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./ModalPrimeiroAcesso.vue?vue&type=style&index=1&id=1f4d69f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f4d69f1",
  null
  
)

export default component.exports